import i18n from '@/i18n';
import { hasGroup } from '@/router/groups';
import invoices from '@/router/invoices';
import live from '@/router/live';
import { menuManager } from '@/router/menuManager';
import reports from '@/router/reports';
import { hasRole } from '@/router/roles';
import Main from '@/views/Main';
import Unauthorized from '@/views/Unauthorized';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Main,
        redirect: { name: 'live' },
        meta: { authorize: {} },
        children: [live, reports, menuManager, invoices],
    },
    {
        path: '/unauthorized',
        component: Unauthorized,
        name: 'unauthorized',
        meta: { authorize: {}, title: 'shared.unauthorized' },
    },
    {
        path: '*',
        redirect: '/',
        meta: { authorize: {} },
    },
];

const router = new VueRouter({
    routes,
    scrollBehavior(to, _, savedPosition) {
        const { left, top } = savedPosition || to.meta?.scrollPosition || { left: 0, top: 0 };

        return { x: left, y: top };
    },
});

export const isAuthorized = ({ roles = [], groups = [] }) => {
    if (!roles.length && !groups.length) {
        return true;
    }

    return roles.some((role) => hasRole(role)) || groups.some((group) => hasGroup(group));
};

export const handleUserRedirection = (to, next) => {
    const { authorize = {} } = to.meta ?? {};

    if (!isAuthorized(authorize)) {
        return next({ name: 'unauthorized' });
    } else if (to.name === 'unauthorized' && isAuthorized(live.meta.authorize)) {
        return next({ name: 'live' });
    }

    return next();
};

export const beforeNavigation = (to, from, next) => {
    if (from.meta?.scrollPosition) from.meta.scrollPosition.top = window.scrollY;

    if (Vue.prototype.$auth.keycloakClient?.authenticated) {
        Vue.prototype.$errorReporting.errorReporter.setUser(Vue.prototype.$auth.user.sub);
        handleUserRedirection(to, next);
    } else {
        Vue.prototype.$auth.signIn().then(() => {
            handleUserRedirection(to, next);
        });
    }
};

router.beforeEach((to, from, next) => {
    beforeNavigation(to, from, next);
});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        let pageTitle = null;

        if (to.meta && to.meta.title) {
            const translationKey = `${to.meta.title}`;
            pageTitle = i18n.te(translationKey) ? i18n.t(translationKey) : 'Astro';
            document.title = pageTitle;
        }
    });
});

export default router;
