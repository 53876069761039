import { roles } from './roles';
const Home = () => import(/* webpackChunkName: "one-board" */ '@/views/Live/Home.vue');
const LiveKitchen = () => import(/* webpackChunkName: "one-board" */ '@/views/Live/LiveKitchen.vue');
const Portal = () => import(/* webpackChunkName: "one-board" */ '@/views/Live/Portal.vue');

export default {
    path: '/live',
    keyName: 'live',
    display: true,
    component: Home,
    icon: 'fa-solid fa-house',
    meta: { authorize: { roles: [roles.LiveViewer, roles.RestaurantLiveViewer] } },
    children: [
        {
            path: '',
            name: 'live',
            keyName: 'live-portal',
            display: false,
            component: Portal,
            meta: {
                authorize: { roles: [roles.LiveViewer, roles.RestaurantLiveViewer] },
                title: 'menu.live',
            },
        },
        {
            component: LiveKitchen,
            display: false,
            keyName: 'live-kitchen',
            meta: {
                authorize: { roles: [roles.LiveViewer, roles.RestaurantLiveViewer] },
                title: 'menu.live-kitchen',
            },
            name: 'live-kitchen',
            path: 'kitchen/:locationUuid',
            props: true,
        },
    ],
};
