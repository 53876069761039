import { groupBy, keyBy } from 'lodash';

const getConcepts = (state) => state.concepts;

const getConceptsMap = (_state, getters) => keyBy(getters.getConcepts, 'uuid');

const getCurrentKitchen = (state) => state.currentKitchen;

const getKitchens = (state) => state.kitchens;

const getLiveStatus = (state) => state.liveStatus;

const getReviews = (state) => state.reviews;

const getMissingItemsOrders = (state) => state.missingItemsOrders;

const getMissingItemsTimeSeries = (state) => state.missingItemsTimeSeries;

const getActivePlatformsSortedByLabels = (state) =>
    state.platforms
        .filter(({ is_deleted }) => !is_deleted)
        .sort((platformA, platformB) => platformA.label.localeCompare(platformB.label));

const getPlatformByUuid = (state) => (platformUuid) => state.platforms.find(({ uuid }) => uuid === platformUuid);

const getOnboardingEnabledPlatforms = (state, getters) => {
    const activePlatforms = getters.getActivePlatformsSortedByLabels;
    return activePlatforms.filter(({ is_onboarding_enabled }) => is_onboarding_enabled);
};

const getConceptsSortedByLabels = (state) =>
    [...state.concepts].sort((conceptA, conceptB) => conceptA.label.localeCompare(conceptB.label));

const getActiveConceptsSortedByLabels = (_state, getters) =>
    getters.getConceptsSortedByLabels.filter(({ is_deleted }) => !is_deleted);

const getActiveExternalConceptsSortedByLabels = (_state, getters) =>
    getters.getActiveConceptsSortedByLabels.filter(({ is_internal }) => !is_internal);

const getConceptByUuid = (state) => (conceptUuid) => state.concepts.find(({ uuid }) => uuid === conceptUuid);

const getKitchensSortedByLabels = (state) =>
    [...state.kitchens].sort((kitchenA, kitchenB) => kitchenA.label.localeCompare(kitchenB.label));

const getKitchenByLocationUuid = (state) => (locationUuid) =>
    state.kitchens.find(({ location_uuid }) => location_uuid === locationUuid);

const getKitchenMapByLocationUuid = (state) => keyBy(state.kitchens, 'location_uuid');

const getPlatforms = (state) => state.platforms;

const getPlatformsMap = (_state, getters) => keyBy(getters.getPlatforms, 'uuid');

const getPlatformsIdMap = (state) =>
    state.platforms.reduce(
        (platformAcc, platform) => ({
            [platform.id]: platform,
            ...platformAcc,
        }),
        {}
    );

const getRestaurantByUuid = (state) => (restaurantUuid) => state.restaurants[restaurantUuid];

const getRestaurantList = (state) => Object.values(state.restaurants);

const getRestaurants = (state) => state.restaurants;

const getRestaurantsGroupByLocationUuid = (state) => groupBy(state.restaurants, 'location_uuid');

const getActiveKitchensSortedByLabels = (_state, getters) =>
    getters.getKitchensSortedByLabels.filter(({ is_deleted }) => !is_deleted);

export default {
    getActiveConceptsSortedByLabels,
    getActiveExternalConceptsSortedByLabels,
    getActiveKitchensSortedByLabels,
    getActivePlatformsSortedByLabels,
    getConceptByUuid,
    getConcepts,
    getConceptsMap,
    getConceptsSortedByLabels,
    getCurrentKitchen,
    getKitchenByLocationUuid,
    getKitchenMapByLocationUuid,
    getKitchens,
    getKitchensSortedByLabels,
    getLiveStatus,
    getMissingItemsOrders,
    getMissingItemsTimeSeries,
    getOnboardingEnabledPlatforms,
    getPlatformByUuid,
    getPlatforms,
    getPlatformsMap,
    getPlatformsIdMap,
    getRestaurantByUuid,
    getRestaurantList,
    getRestaurants,
    getRestaurantsGroupByLocationUuid,
    getReviews,
};
