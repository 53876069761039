import { navigateToUrl } from 'single-spa';
import { hasRole, roles } from './roles';

const Finance = () => import(/* webpackChunkName: "one-board" */ '@/views/Reportings/Finance.vue');
const Home = () => import(/* webpackChunkName: "one-board" */ '@/views/Reportings/Home.vue');
const Performances = () => import(/* webpackChunkName: "one-board" */ '@/views/Reportings/Performances.vue');

const beforeEnter = (to, from, next) => {
    const tabs = [
        {
            hasRole: hasRole(roles.RestaurantRevenue),
            to: { name: 'performances' },
        },
        {
            hasRole: hasRole(roles.FinanceViewer) || hasRole(roles.RestaurantRevenue),
            to: { name: 'finance' },
        },
        {
            navigate: () => navigateToUrl('/r/reports/quality'),
            hasRole: hasRole(roles.QualityViewer) || hasRole(roles.RestaurantPerformance),
        },
        {
            navigate: () => navigateToUrl('/r/reports/sales'),
            hasRole: hasRole(roles.PerformanceViewer) || hasRole(roles.RestaurantRevenue),
        },
        {
            navigate: () => navigateToUrl('/r/reports/refunds'),
            hasRole: hasRole(roles.RefundsViewer) || hasRole(roles.RestaurantRevenue),
        },
        {
            navigate: () => navigateToUrl('/r/reports/orders-details'),
            hasRole: hasRole(roles.OrdersViewer) || hasRole(roles.RestaurantRevenue),
        },
        {
            navigate: () => navigateToUrl('/r/reports/hygiene'),
            hasRole: hasRole(roles.HygieneViewer) || hasRole(roles.RestaurantPerformance),
        },
        {
            navigate: () => navigateToUrl('/r/reports/delivery-control'),
            hasRole: hasRole(roles.DeliveriesPerformance),
        },
    ].filter((tab) => tab.hasRole);

    const hasAccess = tabs.some((tab) => tab.to?.name === to.name);
    if (hasAccess) {
        next();
    } else if (tabs.length) {
        const { navigate, to } = tabs[0];
        navigate ? navigate() : next(to);
    } else {
        navigateToUrl('/r/unauthorized');
    }
};

export default {
    children: [
        {
            beforeEnter,
            component: Finance,
            name: 'finance',
            path: 'finance',
            meta: { title: 'reportings.financeTab.label' },
        },
        {
            beforeEnter,
            component: Performances,
            name: 'performances',
            path: 'performances',
            meta: { title: 'reportings.performancesTab.label' },
        },
    ],
    component: Home,
    display: true,
    icon: 'fa-solid fa-chart-simple',
    keyName: 'reports',
    meta: {
        authorize: {
            roles: [
                roles.FinanceViewer,
                roles.HygieneViewer,
                roles.OrdersViewer,
                roles.PerformanceViewer,
                roles.QualityViewer,
                roles.RestaurantPerformance,
                roles.RestaurantRevenue,
            ],
        },
        title: 'reports',
    },
    name: 'reports',
    path: '/reports',
    redirect: { name: 'performances' },
};
