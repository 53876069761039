import { invoices } from '@/store/modules/invoices';
import { menuManager } from '@/store/modules/menuManager';
import { notifier } from '@/store/modules/notifier';
import { status } from '@/store/modules/status';
import { ui } from '@/store/modules/ui';
import Vue from 'vue';
import Vuex from 'vuex';
import { oneBoard } from './modules/oneBoard';
import { orders } from './modules/orders';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        invoices,
        menuManager,
        notifier,
        oneBoard,
        orders,
        status,
        ui,
    },
});
